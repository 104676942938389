import React from "react";
import { Switch, Route, withRouter, Link, Navigate } from "react-router-dom";
import MainPage from "./MainPage";
import Header from "./Header";
import ChooseClientOrBarber from "./Screens/ChooseClientOrBarber";
import MyBarberAdminPage from "./Screens/MyBarberAdminPage";
import MyBarberPage from "./Screens/MyBarberPage";
import MyBarberAdminNew from "./MyBarberAdminNew";

function Router() {
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <MyBarberAdminNew />
          {/* <ChooseClientOrBarber /> */}
        </Route>
        {/* <Route exact path="/Dashboard">
          <Header />
        </Route>
        <Route exact path="/ChooseClientOrBarber">
          <ChooseClientOrBarber />
        </Route>
        <Route exact path="/mybarberadmin">
          <MyBarberAdminPage />
        </Route>
        <Route exact path="/mybarber">
          <MyBarberPage />
        </Route>
        <Route exact path="/lead">
          <MyBarberAdminNew />
        </Route> */}
      </Switch>
    </div>
  );
}
//MainPage
export default withRouter(Router);
