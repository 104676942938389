import React, { useState, useEffect } from "react";
import AdminComp1Image from "../Images/AdminComp1Image.png";
import numberone from "../Images/numberone.png";
import numbertwo from "../Images/numbertwo.png";
import numberthree from "../Images/numberthree.png";
import numberfour from "../Images/numberfour.png";
import wave from "../Images/wave.png";
import checked from "../Images/checked.png";
import google from "../Images/google.png";
import apple from "../Images/apple.png";
import { useTranslation } from "react-i18next";
import Barber from "../Barber";

export default function AdminComp1() {
  const { t, i18n } = useTranslation();

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <a
        style={{
          fontSize: 40,
          fontWeight: "bold",
          textAlign: "center",
          paddingTop: 30,
        }}
      >
        MyBarber Admin
      </a>

      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingTop: 10,
          width: "80%",
        }}
      >
        <a style={{ fontSize: 40, fontWeight: "bold", textAlign: "center" }}>
          تطبيق
          <a
            style={{
              color: "#2563eb",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {" "}
            بسيط وكامل
          </a>{" "}
          لإدارة وحجز المواعيد
        </a>
      </div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
          paddingTop: 30,
        }}
      >
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <a style={{ fontSize: 25, fontWeight: "bold", textAlign: "center" }}>
            اجا الوقت يكون عندك تطبيق لحل كل مشاكل المواعيد والحجوزات
          </a>
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingTop: 10,
          }}
        >
          <a style={{ fontSize: 25, fontWeight: "bold", textAlign: "center" }}>
            (مع تذكيرات للحجوزات , واحصائيات)
          </a>
        </div>
      </div>
      <div style={{ paddingTop: 40 }}>
        <img
          src={AdminComp1Image}
          alt="logo"
          style={{
            width: 300,
          }}
        />
      </div>
      <img
        src={wave}
        alt="logo"
        style={{
          width: "100%",
        }}
      />
      <div
        style={{
          paddingTop: 40,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <a style={{ fontSize: 35, fontWeight: "bold" }}>لمين التطبيق ملائم</a>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            paddingTop: 10,
          }}
        >
          <a style={{ fontSize: 22, direction: "rtl", fontWeight: "bold" }}>
            <img
              src={numberone}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            اذا انت حلاق ؟
          </a>
          <div style={{ paddingTop: 8 }}></div>

          <a style={{ fontSize: 22, direction: "rtl", fontWeight: "bold" }}>
            {" "}
            <img
              src={numbertwo}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            بعدك بتسجل على ورق ؟
          </a>
          <div style={{ paddingTop: 8 }}></div>

          <a style={{ fontSize: 22, direction: "rtl", fontWeight: "bold" }}>
            <img
              src={numberthree}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            بتحكي بالتلفون كل يوم اكثر من ساعه مع الزبائن ؟
          </a>

          <div style={{ paddingTop: 8 }}></div>

          <a style={{ fontSize: 22, direction: "rtl", fontWeight: "bold" }}>
            <img
              src={numberfour}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            بدك تحسن كل شئ الو علاقه بالحجوزات وعملك ووقتك ؟
          </a>
        </div>
      </div>

      <div
        style={{
          paddingTop: 40,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <a style={{ fontSize: 35, fontWeight: "bold", textAlign: "center" }}>
          بشو بتميز تطبيقنا وبشو رح يساعدك
        </a>
        <a style={{ fontSize: 22, direction: "rtl", paddingTop: 10 }}>
          <a
            style={{
              backgroundColor: "#7cc2ff",
              paddingLeft: 5,
              paddingRight: 5,
              borderRadius: 5,
              fontSize: 28,
            }}
          >
            يمتاز التطبيق
          </a>{" "}
          انو انتي عندك التحكم الكامل بطريقة الحجوزات
        </a>
        <a style={{ fontSize: 22, direction: "rtl", paddingTop: 5 }}>
          يعني تقدر تختار انو انت تحجز المواعيد للزبائن و/او انو لزبون يحجز موعد
          من خلال تطيبق الزبائن MyBarber من دون التواصل معك مباشره (رح يصلك
          رساله لمن زبون يحجز او يلغي الموعد)
        </a>
        <div
          style={{
            paddingTop: 10,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <a
              style={{
                backgroundColor: "#7cc2ff",
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 5,
                fontSize: 28,
              }}
            >
              بشو رح يساعدك
            </a>{" "}
            <div style={{ paddingTop: 8 }}></div>
            <a style={{ fontSize: 22, direction: "rtl", fontWeight: "bold" }}>
              <img
                src={numberone}
                alt="logo"
                style={{
                  width: 25,
                }}
              />{" "}
              رح تدير الحجوزات وطلبات الزبائن من مكان واحد!
            </a>
            <div style={{ paddingTop: 8 }}></div>
            <a style={{ fontSize: 22, direction: "rtl", fontWeight: "bold" }}>
              <img
                src={numbertwo}
                alt="logo"
                style={{
                  width: 25,
                }}
              />{" "}
              صفحة لرؤية كل الاحصائيات (عدد الحجوزات , مدخولات , قوائم الزبائن
              لكل تاريخ)
            </a>
            <div style={{ paddingTop: 8 }}></div>
            <a style={{ fontSize: 22, direction: "rtl", fontWeight: "bold" }}>
              <img
                src={numberthree}
                alt="logo"
                style={{
                  width: 25,
                }}
              />{" "}
              التواصل مع جميع الزبائن من مكان واحد وبضغطة زر واحده
            </a>
            <div style={{ paddingTop: 8 }}></div>
            <a style={{ fontSize: 22, direction: "rtl", fontWeight: "bold" }}>
              <img
                src={numberfour}
                alt="logo"
                style={{
                  width: 25,
                }}
              />{" "}
              وهناك الكثير من الميزات داخل التطبيق
            </a>
          </div>
        </div>
        <img
          src={wave}
          alt="logo"
          style={{
            width: "100%",
          }}
        />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <a
            style={{
              textAlign: "center",
              fontSize: 50,
              fontWeight: "bold",
              color: "#0077e6",
            }}
          >
            شو كمان
          </a>
          <a
            style={{
              fontSize: 22,
              direction: "rtl",
              fontWeight: "bold",
              paddingTop: 5,
            }}
          >
            {" "}
            <img
              src={checked}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            سوف تحصل على شعار ( Logo ) مجانا
          </a>
          <a
            style={{
              fontSize: 22,
              direction: "rtl",
              fontWeight: "bold",
              paddingTop: 5,
            }}
          >
            {" "}
            <img
              src={checked}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            حمل التطبيق وبلش استخدم كل الخدمات في الحال
          </a>
          <a
            style={{
              fontSize: 22,
              direction: "rtl",
              fontWeight: "bold",
              paddingTop: 5,
            }}
          >
            {" "}
            <img
              src={checked}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            التطبيق مجانا 60 يوم (لن نسالك عن اي شئ بالنسبة للدفع)
          </a>
          <a
            style={{
              fontSize: 22,
              direction: "rtl",
              fontWeight: "bold",
              paddingTop: 5,
            }}
          >
            {" "}
            <img
              src={checked}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            بعدها اذا اعجبك وتريد استخدامه سنتواصل معك, فقط بسعر{" "}
            <a style={{ color: "#2563eb" }}>149₪</a> بالشهر
          </a>
          <a
            style={{
              fontSize: 22,
              direction: "rtl",
              fontWeight: "bold",
              paddingTop: 5,
            }}
          >
            {" "}
            <img
              src={checked}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            ايضا يُسعدنا التواصل معك شخصيا لإرشادك كيفية استخدام التطبيق
          </a>
        </div>
        <div
          style={{
            //paddingTop: 30,
            direction: i18n.resolvedLanguage === "en" ? "" : "rtl",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <a
            style={{
              fontWeight: "bold",
              fontSize: 25,
            }}
          >
            حمل التطبيق الآن
            {/* {t("downloadNow")} */}
          </a>
          <div
            style={{
              paddingTop: 10,
              display: "flex",
              // flexDirection:
              //   window.innerWidth > 1000 ? "row" : "column", // Use the dynamically set flexDirection here
              flex: 1,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              href="https://apps.apple.com/us/app/mybarber-admin/id6456844911"
              target="_blank"
            >
              <img
                onClick={() => {
                  console.log("apple");
                }}
                src={apple}
                style={{
                  width: window.innerWidth > 766 ? 185 : 160,
                  height: window.innerWidth > 766 ? 60 : 50,
                  cursor: "pointer",
                }}
              />
            </a>
            <div style={{ width: window.innerWidth > 766 ? 30 : 20 }}></div>
            <a
              href="https://play.google.com/store/apps/details?id=com.mohammad1998a.MyBarberAdmin&hl=en&gl=US"
              target="_blank"
            >
              <img
                onClick={() => {
                  console.log("google");
                }}
                src={google}
                style={{
                  width: window.innerWidth > 766 ? 185 : 160,
                  height: window.innerWidth > 766 ? 60 : 50,
                  cursor: "pointer",
                }}
              />
            </a>
          </div>
        </div>
        <Barber />
      </div>
    </div>
  );
}
