// import React from "react";
// //import { Switch, Route, withRouter, Link, Navigate } from "react-router-dom";
// import { HashRouter as Router, Route } from "react-router-dom";

// import "./App.css";
// import MainPage from "./MainPage";
// function App() {
//   return (
//     <div>
//       <header>
//         {/* <Switch> */}
//         <Route exact path="/">
//           <MainPage />
//         </Route>
//         {/* <Route exact path="/ScrollToTop">
//           <ScrollToTop />
//         </Route> */}
//         {/* </Switch> */}
//       </header>
//     </div>
//   );
// }
// export default App;

import React from "react";
import MainPage from "./MainPage";
import { Switch, Route, withRouter, Link, Navigate } from "react-router-dom";
import Router from "./Router";
//export const MyDomain = "http://mybarber.co.il/";
export const MyDomain = "http://localhost:3000/";

export default function App() {
  return (
    <div>
      {/* <MainPage /> */}
      <div style={{}}>
        <Router />
      </div>
    </div>
  );
}
