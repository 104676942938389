import React from "react";
import recordbutton from "../Images/recordbutton.png";
import shape from "../Images/shape.png";

export default function AdminComp3() {
  return (
    <div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingTop: 30,
          flexDirection: "column",
        }}
      >
        <a
          style={{
            textAlign: "center",
            fontSize: 35,
            fontWeight: "bold",
            //color: "#0077e6",
          }}
        >
          <a style={{ color: "#2563eb" }}> الوقت </a>لا يمكن شراؤة, سنجعلك تكسب
          الوقت
        </a>
        <div
          style={{
            backgroundColor: "#f5f5f4",
            height: 320,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        >
          <div
            style={{
              height: "35%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                //color: "#0077e6",
              }}
            >
              السهولة والراحه
            </a>
          </div>
          <div
            style={{
              background: "linear-gradient(to bottom,#6366f1,#4338ca)",
              height: "65%",
              width: "100%",
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              style={{
                fontSize: 22,
                direction: "rtl",
                fontWeight: "bold",
                paddingTop: 5,
                padding: 10,
                textAlign: "center",
                color: "white",
              }}
            >
              تقدر حتى من البيت تحجز المواعيد للزبائن ويمكن للزبائن حجز المواعيد
              من تطبيق الزبائن MyBarber 24/7 (اذا اردت)
            </a>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f5f5f4",
            height: 320,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        >
          <div
            style={{
              height: "35%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                //color: "#0077e6",
              }}
            >
              تذكير اوتوماتي
            </a>
          </div>
          <div
            style={{
              background: "linear-gradient(to bottom,#eab308,#a16207)",
              height: "65%",
              width: "100%",
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              style={{
                fontSize: 22,
                direction: "rtl",
                fontWeight: "bold",
                paddingTop: 5,
                padding: 10,
                textAlign: "center",
                color: "white",
              }}
            >
              سيتلقى زبائنكم تذكير لموعدهم بشكل اوتوماتي, وستستطيع التركيز وان
              لا تقلك بتذكير الزبون بالمجئ
            </a>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f5f5f4",
            height: 320,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        >
          <div
            style={{
              height: "35%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                //color: "#0077e6",
              }}
            >
              الساعات والتواريخ
            </a>
          </div>
          <div
            style={{
              background: "linear-gradient(to bottom,#22c55e,#15803d)",
              height: "65%",
              width: "100%",
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              style={{
                fontSize: 22,
                direction: "rtl",
                fontWeight: "bold",
                paddingTop: 5,
                padding: 10,
                textAlign: "center",
                color: "white",
              }}
            >
              يمكن اختيار ساعات وايام العمل وايضا الايام التي يمكن الحجز منها
            </a>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f5f5f4",
            height: 320,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        >
          <div
            style={{
              height: "35%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                //color: "#0077e6",
              }}
            >
              التواصل مع الزبائن
            </a>
          </div>
          <div
            style={{
              background: "linear-gradient(to bottom,#ef4444,#b91c1c)",
              height: "65%",
              width: "100%",
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              style={{
                fontSize: 22,
                direction: "rtl",
                fontWeight: "bold",
                paddingTop: 5,
                padding: 10,
                textAlign: "center",
                color: "white",
              }}
            >
              مش كلنا منحفظ الارقام, وفش حاجة, كل الزبائن رح بنضافو اوتوماتي
              لقائمة الزبائن خاصتكم ويمكن التواصل معهم كلهم بضغطة زر
            </a>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f5f5f4",
            height: 320,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        >
          <div
            style={{
              height: "35%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                //color: "#0077e6",
              }}
            >
              ميزات اُخرى{" "}
            </a>
          </div>
          <div
            style={{
              background: "linear-gradient(to bottom,#06b6d4,#0e7490)",
              height: "65%",
              width: "100%",
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <a
              style={{
                fontSize: 22,
                direction: "rtl",
                fontWeight: "bold",
                paddingTop: 5,
                padding: 5,
                color: "white",
              }}
            >
              <img
                src={shape}
                alt="logo"
                style={{
                  width: 10,
                }}
              />{" "}
              يمكن اضافة موقع صالون الحلاقة داخل التطبيق مع waze
            </a>
            <a
              style={{
                fontSize: 22,
                direction: "rtl",
                fontWeight: "bold",
                paddingTop: 5,
                padding: 5,
                color: "white",
              }}
            >
              <img
                src={shape}
                alt="logo"
                style={{
                  width: 10,
                }}
              />{" "}
              اضافة ساعات العمل{" "}
            </a>
            <a
              style={{
                fontSize: 22,
                direction: "rtl",
                fontWeight: "bold",
                paddingTop: 5,
                padding: 5,
                color: "white",
              }}
            >
              <img
                src={shape}
                alt="logo"
                style={{
                  width: 10,
                }}
              />{" "}
              يوجد اكثر من حلاق بالصالون؟ يمكن اضافتهم تحت اسم محلقة واحده{" "}
            </a>
            <a
              style={{
                fontSize: 22,
                direction: "rtl",
                fontWeight: "bold",
                paddingTop: 5,
                padding: 5,
                color: "white",
              }}
            >
              <img
                src={shape}
                alt="logo"
                style={{
                  width: 10,
                }}
              />{" "}
              ايضا هناك قائمة للزبائن الذين لم يدفعو
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
