import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          welcome: "Welcome to our application",
          simpler: "Simpler",
          faster: "Faster",
          easier: "Easier",
          //MyBarber Admin
          goto: "Go to",
          appointmentsmanagementapp: "Appointments management app",
          bio: "Are you a barber? Do you want to organize your work and yourtime to increase lost profits by talking on the phone and making appointments for clients? You want to have lists of clients with the date and times that you choose and be comfortable and organized.",
          bio2: "We provide you with the MyBarber Admin application",
          providesyou: "provides you",
          addappointments: "Add appointments",
          discOne:
            "from the hour to the hour you choose, along with the duration of the appointments and on what date",
          makeappointments: "Make appointments",
          discTwoPart1:
            "Add the customer to the appointments list, or the customer can book an appointment himself through the ",
          discTwoMyBarber: "MyBarber",
          discTwoPart2:
            " customer application, and can also modify or cancel the appointment.",
          discThree:
            "Communicate comfortably with customers through the application",
          statistics: "Statistics ",
          discFour:
            "can be seen for each date, the number of customers who have booked, and the list of names along with the times of their appointments.",
          applicationforyourcustomers: "Application for your customers",
          discFivePart1:
            "In order for a customer to be able to book an appointment with you, we have another application called",
          discFiveMyBarber: " MyBarber ",
          discFivePart2:
            "Through this application, your customer will be able to book a session or send a request to book an appointment.",
          paidlist: "Paid list",
          discSix:
            "For customers who have not paid, you can save them in the list of customers who have not paid",
          features: "Features",
          discSeven:
            "There are many features, such as adding the location of the barber shop, adding working hours, and if another barber works with you, he can be added to your barber shop.",
          downloadNow: "Download the application now",
          sendDetails: "Send your details, and we will contact with.",
          videoexplain: "Video explain the app in 3 min",
          ImagesfromtheappAdmin: "Images from the app",

          //MyBarber
          bio1: "Are you tired of standing in waiting lines, or is it difficult for you to communicate with your barber through messages and calls? We have a solution with the MyBarber application that enables you to choose the date and hour that suits you and book your appointment without communicating directly with your barber, or you can send him a request comfortably and he will contact you directly.",
          mybarber: "MyBarber",
          providesyou1: "provides you",
          first1: "Easy appointment booking process",
          discOne1:
            "With the click of a button, you choose the date and hour you want and book your appointment",
          second1: "Reminders and notifications",
          discTwo1:
            "The application will send you a reminder notification half an hour before your appointment",
          third1: "Send a request",
          discThree1:
            "You can also send a request to the barber for the date you wish to book",
          discFour1:
            "You can book and cancel the appointment at any time you wish",
          five1: "Choose your barber",
          discFive1:
            "There is a list of barbers from which you can choose your barber",
          six1: "Appointment details",
          discSix1:
            "You will be able to see the details of your appointment within the app",
          discSeven1: "There are a lot of features",
          appointmentBookingapp: "Appointment Booking app",
          areYou: "Are you",
          barber: "Barber ?",
          //ContactUs
          yourname: "Your name*",
          yourphonenumber: "Your phone number*",
          message: "Message",
          submit: "submit",
          fullName: "Full name*",
          barberShopName: "Barber shop name*",
          city: "City*",
          sentReq: "The request sent successfully",
          wewillcontactyouAssoonaspossible:
            "We will contact you As soon as possible",
          error: "Please fill the name & phone number!",
          error1: "Please fill all the fields!",
          contactUs: "Contact Us",
          backto: "back to",
          chooseLanguage: "Choose Language",
          wantgotobarbersPage: "want go to barbers Page ?",
          yes: "Yes",
          no: "No",
          photos: "Photos",
          videos: "Videos",
          photosfromapp: "Photos from app",
          videosexplainusetheapp: "Videos explain use the app",

          //ChooseClientOrBarber
          ask1: "Are you barber or client for barber ?",
          iambarber: "i am barber",
          iamclientforbarber: "I am client for barber",
        },
      },

      ar: {
        translation: {
          welcome: "مرحبا بكم في تطبيقنا",
          simpler: "أبسط",
          faster: "أسرع",
          easier: "أسهل",
          //MyBarber Admin
          goto: "اذهب إلى",
          appointmentsmanagementapp: "تطبيق إدارة وحجز مواعيد",
          //bio: "هل انت حلاق, تريد تنظيم عملك ووقتك لزيادة الربح الضائع بالتحدث على الهاتف وتحديد المواعيد للزبائن, تريد ان يكون لديك قوائم للزبائن مع التاريخ والاوقات التي تختارها انت وتكون مريحة ومنظمة ",
          //bio: "انت حلاق, بدك تنظم وقتك وعملك ويكون عندك قوائم للزبائن مع التاريخ والاوقات الي انت بتختارهن, وانو انت تحجزلهم او انو زبائنك يحجزو عن طريق تطبيق الزبائن",
          bio: "انت حلاق؟ بعدك بتسجل الحجوزات على ورق؟ بدك تحسن وقتك وعملك؟ منوفرلك تطبيق MyBarber Admin تقدر تسجل الحجوزات بالاوقات الي انت تختارها مع جميع الامكانيات مثل تعديل او الغاء الحجز,ويوجد صفحة مع قوائم للاحصائات. وكمان في تطبيق ثاني لزبائنك يقدرو يحجزو عن طريقه اذا انت بدك اسم التطبيق",
          bio2: " منوفرلك تطبيق MyBarber Admin",
          providesyou: "يوفر لك",
          addappointments: "إضافة مواعيد",
          discOne:
            "من الساعة إلى الساعة التي تختارها، بالإضافة إلى مدة الموعد وفي أي تاريخ",
          makeappointments: "حجز مواعيد",
          discTwo:
            "اضافة الزبون الى قائمة المواعيد او يمكن للزبون حجز موعدة بنفسة من خلال تطبيق الزبائن MyBarber وايضا يمكن تعديل او الغاء الموعد",
          discTwoPart1:
            "اضافة الزبون الى قائمة المواعيد , او يمكن للزبون حجز موعدة بنفسة من خلال تطبيق الزبائن",
          discTwoMyBarber: " MyBarber ",
          discTwoPart2: " وايضا يمكن تعديل او الغاء الموعد",
          discThree: "التواصل بكل اريحية مع الزبائن من خلال التطبيق",
          statistics: "الاحصائيات ",
          discFour:
            "يمكن روية الاحصائيات لكل تاريخ عدد الزبائن الذين حجزو وقائمة الاسماء مع اوقات مواعيدهم",
          applicationforyourcustomers: "تطبيق لزبائنك",
          discFivePart1:
            "لكي يتمكن الزبون من حجز موعد عندك لدينا تطيبق اخر باسم",
          discFiveMyBarber: " MyBarber ",
          discFivePart2:
            "من خلال هذا التطيبق سيتمكن زبونك من حجز دوراو ارسال طلب لحجز موعد.",
          paidlist: "قائمة المدفوعات",
          discSix:
            "للزبائن الذين لم يدفعو يمكنك حفظهم بقائة الزبائن الذين لم يدفعو",
          features: "ميزات",
          discSeven:
            "هناك الكثير من الخصائص مثل اضافة موقع المحلقة ,اضافة ساعات العمل , اذا كان يعمل معك حلاق اخر يمكن اضافتة الى المحلقة الخاصة بك",
          downloadNow: "حمل التطبيق الآن",
          sendDetails: "أرسل بياناتك ونحن سنقوم بالتواصل معك",
          videoexplain: "فيديو شرح التطبيق في ثلاث دقائق",
          ImagesfromtheappAdmin: "صور من التطبيق",

          //MyBarber
          bio1: "هل سئمت الوقوف في طوابير الانتظار, او يصعب عليك التواصل مع حلاقك من خلال الرسائل والاتصالات. لدينا الحل بتطبيق MyBarber يمكنك من اختيار التاريخ والساعة التي تناسبك وحجز موعدك من دون التواصل مباشرة مع حلاقك, او يمكن ارسال لة طلب بكل اريحية وسيقوم مباشرة بالتواصل معك",
          providesyou1: " يوفر لك",
          first1: "عملية حجز موعد سهلة",
          discOne1:
            "بضغطة زر تقوم باختيار التاريخ والساعة التي ترغب بها وحجز موعدك",
          second1: "التذكيرات والاشعارات",
          discTwo1: "سيقوم التطبيق بارسال اشعار تذكير لك قبل موعدك بنصف ساعة",
          third1: " ارسال طلب",
          discThree1:
            "يمكنك ايضا ارسال طلب للحلاق بالتاريخ الذي ترغب بالحجز بة",
          discFour1: "يمكنك حجز والغاء الموعد باي وقت ترغب بة",
          five1: "اختر حلاقك",
          discFive1: "هناك قائمة من الحلاقين يمكنك اختيار حلاقك من بينهم",
          six1: "تفاصيل الموعد",
          discSix1: "ستتمكن من روية تفاصيل موعدك داخل التطبيق",
          discSeven1: "وهناك الكثير من الميزات",
          appointmentBookingapp: "تطبيق حجز المواعيد",
          areYou: "هل انت ",
          barber: "حلاق ؟",
          yourname: "اسمك*",
          yourphonenumber: "رقم هاتفك*",
          message: "رسالة",
          submit: "ارسل",
          fullName: "الاسم الكامل*",
          barberShopName: "اسم المحلقة*",
          city: "البلد*",
          sentReq: "تم إرسال الطلب بنجاح",
          wewillcontactyouAssoonaspossible: "سنتواصل معك بأقرب وقت ممكن",
          error: "!يرجى ملء الاسم ورقم الهاتف",
          error1: "!الرجاء تعبئة جميع الحقول",
          contactUs: "تواصل معنا",
          backto: "ارجع الى",
          chooseLanguage: "اختر اللغة",
          wantgotobarbersPage: "هل تريد الذهاب إلى صفحة الحلاقين؟",
          yes: "نعم",
          no: "لا",
          photos: "صور",
          videos: "فيديوهات",
          photosfromapp: "صور من التطبيق",
          videosexplainusetheapp: "فيديوهات شرح استعمال التطبيق",
          //ChooseClientOrBarber
          ask1: "هل انت حلاق ام زبون للحلاق ؟",
          iambarber: "انا حلاق",
          iamclientforbarber: "انا زبون للحلاق",
        },
      },
      he: {
        translation: {
          welcome: "ברוכים הבאים לאפליקציה שלנו",
          simpler: "פשוט",
          faster: "מהיר",
          easier: "קל",
          //MyBarber Admin
          goto: "לך ל",
          appointmentsmanagementapp: "אפליקציית ניהול פגישות",
          bio: "האם אתה ספר? האם אתה רוצה לארגן את העבודה שלך ואת שלך זמן להגדיל את הרווחים האבודים על ידי שיחה בטלפון ו לקבוע פגישות ללקוחות? אתה רוצה לקבל רשימות של לקוחות עם התאריך והשעות שאתה בוחר ותהיה נוח ומאורגן.",
          bio2: "אנו מספקים לך את אפליקציית MyBarber Admin",
          providesyou: "מספק לך",
          addappointments: "הוסף פגישות",
          discOne: "מהשעה ועד השעה שתבחרו, יחד עם משך הפגישה ובאיזה תאריך",
          makeappointments: "להזמין פגישות",
          discTwoPart1:
            "הוספת הלקוח לרשימת התורים, או שהלקוח יכול להזמין תור בעצמו דרך אפליקציית הלקוח",
          discTwoMyBarber: " MyBarber ",
          discTwoPart2: "ניתן גם לשנות או לבטל את הפגישה",
          discThree: "לתקשר בנוחות עם לקוחות דרך האפליקציה",
          statistics: "סטָטִיסטִיקָה ",
          discFour:
            "ניתן לראות סטטיסטיקה לכל תאריך, מספר הלקוחות שהזמינו ורשימת השמות יחד עם זמני הפגישות.",
          applicationforyourcustomers: "אפליקציה ללהלקוחות שלך",
          discFivePart1:
            "על מנת שהלקוח יוכל לקבוע איתך תור, יש לנו אפליקציה נוספת בשם",
          discFiveMyBarber: " MyBarber ",
          discFivePart2:
            "באמצעות אפליקציה זו הלקוח שלך יוכל להזמין פגישה או לשלוח בקשה לקביעת תור.",
          paidlist: "רשימת התשלומים",
          discSix:
            "ללקוחות שלא שילמו, ניתן לשמור אותם ברשימת הלקוחות שלא שילמו",
          features: "פיצ'רים ",
          discSeven:
            "יש הרבה פיצ'רים כמו הוספת מיקום המספרה, הוספת שעות עבודה ואם מספר אחר עובד איתך ניתן לצרף אותו למספרה שלך.",
          downloadNow: "הורד את היישום עכשיו",
          sendDetails: "שלח את פרטיך, ואנו ניצור איתך קשר",
          videoexplain: "סרטון הסבר על האפליקציה תוך 3 דקות",
          ImagesfromtheappAdmin: "תמונות מהאפליקציה",

          //MyBarber
          bio1: "נמאס לכם לעמוד בתורים להמתנה, או שקשה לכם לתקשר עם הספר באמצעות הודעות ושיחות? יש לנו פתרון עם אפליקציית MyBarber המאפשרת לך לבחור את התאריך והשעה המתאימים לך ולקבוע תור מבלי לתקשר ישירות עם הספר שלך, או שתוכל לשלוח לו בקשה בנוחות והוא ייצור איתך קשר ישירות.",
          providesyou1: "מספקת לך",
          first1: "תהליך הזמנת פגישות קל",
          discOne1: "בלחיצת כפתור בוחרים את התאריך והשעה הרצויים ומזמינים תור",
          second1: "תזכורות והתראות",
          discTwo1: "האפליקציה תשלח לך הודעת תזכורת חצי שעה לפני התור",
          third1: "שלח בקשה",
          discThree1: "כמו כן, ניתן לשלוח בקשה לספר לתאריך בו תרצו להזמין",
          discFour1: "ניתן להזמין ולבטל את הפגישה בכל עת שתחפוץ",
          five1: "בחר את הספר שלך",
          discFive1: "ישנה רשימה של מספרים מהם תוכלו לבחור את הספר שלכם",
          six1: "פרטי פגישה",
          discSix1: "תוכל לראות את פרטי הפגישה שלך בתוך האפליקציה",
          discSeven1: "ויש הרבה תכונות",
          appointmentBookingapp: "אפליקציית הזמנת תורים",
          areYou: "האם אתה",
          barber: "סַפָּר ?",
          yourname: "השם שלך*",
          yourphonenumber: "מספר הטלפון שלך*",
          message: "הודעה",
          submit: "שלח",
          fullName: "שם מלא*",
          barberShopName: "שם המספרה*",
          city: "עיר*",
          sentReq: "הבקשה נשלחה בהצלחה",
          wewillcontactyouAssoonaspossible: "ניצור איתך קשר בהקדם האפשרי",
          error: "!נא למלא את השם ומספר הטלפון",
          error1: "!נא למלא את כל השדות",
          contactUs: "צור קשר",
          backto: "תחזור ל-",
          chooseLanguage: "בחר שפה",
          wantgotobarbersPage: "רוצה ללכת לדף הספרים?",
          yes: "כן",
          no: "לא",
          photos: "תמונות",
          videos: "סרטונים",
          photosfromapp: "תמונות מהאפליקציה",
          videosexplainusetheapp: "סרטונים מסבירים את השימוש באפליקציה",
          //ChooseClientOrBarber
          ask1: "האם אתה ספר או לקוח לספר?",
          iambarber: "אני ספר",
          iamclientforbarber: "אני לקוח לספר",
        },
      },
      // Add other languages
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
