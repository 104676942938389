import React from "react";
import AdminComp1 from "./AdminComponent/AdminComp1";
import AdminComp2 from "./AdminComponent/AdminComp2";
import AdminComp3 from "./AdminComponent/AdminComp3";
import whatsapp from "./Images/whatsapp.png";

export default function MyBarberAdminNew() {
  const phoneNumber = "+9720527477128"; // Include the country code
  const initialMessage = encodeURIComponent(
    "السلام عليكم, اريد ان اعرف المزيد"
  );

  const handleWhatsApp = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${initialMessage}`;
    window.open(whatsappUrl, "_self").catch(() => {
      alert(
        "Failed to open WhatsApp. Please ensure you have WhatsApp installed."
      );
    });
  };
  return (
    <div>
      <AdminComp1 />
      <AdminComp2 />
      <AdminComp3 />
      <a
        //href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=hi`}
        onClick={handleWhatsApp}
      >
        <img
          src={whatsapp}
          alt="logo"
          style={{
            position: "fixed", // Fix position relative to the viewport
            bottom: "5px", // Align to the bottom
            left: "10px", // Align to the left
            width: "50px", // Optional: Set the width of the image
            zIndex: 1000, // Optional: Ensure the image is above other content
          }}
        />
      </a>
    </div>
  );
}
