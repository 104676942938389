import React, { useState, useEffect } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { UserOutlined, PhoneFilled } from "@ant-design/icons";
import { AiOutlineMail } from "react-icons/ai";
import { Input, Button, Flex } from "antd";
import Person from "./Person";
import Barber from "./Barber";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

export default function ContactUs({ ButtonColor }) {
  const { t, i18n } = useTranslation();

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //const [ButtonColor, setButtonColor] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          backgroundColor: "#fafafa",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "3%",
        }}
      >
        {/* <div
          style={{
            // backgroundColor: "gray",
            width: window.innerWidth > 766 ? "40%" : "90%",

            justifyContent: "center",
            display: "flex",
          }}
        >
          <button
            style={{
              width: window.innerWidth > 766 ? "50%" : "100%",
              height: 60,
              borderWidth: ButtonColor === 0 ? 3 : 0,
              background: ButtonColor === 0 ? "white" : "#e5e5e5",
              borderColor: "#c7d2fe",
              cursor: "pointer",
              borderStartStartRadius: 10,
              borderEndStartRadius: 10,
              borderStyle: "solid", // You can also set other border styles like dashed, dotted, etc.
            }}
            onClick={() => {
              //setButtonColor(0);
            }}
          >
            <a>Personal</a>
          </button>
          <button
            style={{
              //width: 200,
              width: window.innerWidth > 766 ? "50%" : "100%",
              height: 60,
              borderWidth: ButtonColor === 1 ? 3 : 0,
              background: ButtonColor === 1 ? "white" : "#e5e5e5",
              boxSizing: "border-box", // Add this line
              borderStyle: "solid", // You can also set other border styles like dashed, dotted, etc.
              borderColor: "#c7d2fe",
              cursor: "pointer",
              borderEndEndRadius: 10,
              borderStartEndRadius: 10,
            }}
            onClick={() => {
              //setButtonColor(1);
            }}
          >
            <a>Barber</a>
          </button>
        </div> */}
        <a style={{ fontSize: 20 }}>{t("sendDetails")}</a>
      </div>
      {ButtonColor === 0 ? (
        <>
          <Person />
        </>
      ) : (
        <>
          <Barber />
        </>
      )}
    </div>
  );
}

{
  /* <div>
<div
  style={{
    flex: 1,
    height: 400,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "red",
    width: "50%",
  }}
>
  <div
    style={{
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
      display: "flex",
    }}
  >
    <button
      style={{
        width: "50%",
        height: 60,
        borderWidth: ButtonColor === 0 ? 3 : 0,
        background: ButtonColor === 0 ? "white" : "#e5e5e5",
        borderColor: "#c7d2fe",
        cursor: "pointer",
        borderStartStartRadius: 10,
        borderEndStartRadius: 10,
        borderStyle: "solid", // You can also set other border styles like dashed, dotted, etc.
      }}
      onClick={() => {
        setButtonColor(0);
      }}
    >
      <a>Personal</a>
    </button>
    <button
      style={{
        width: "50%",

        //width: 200,
        height: 60,
        borderWidth: ButtonColor === 1 ? 3 : 0,
        background: ButtonColor === 1 ? "white" : "#e5e5e5",
        boxSizing: "border-box", // Add this line
        borderStyle: "solid", // You can also set other border styles like dashed, dotted, etc.
        borderColor: "#c7d2fe",
        cursor: "pointer",
        borderEndEndRadius: 10,
        borderStartEndRadius: 10,
      }}
      onClick={() => {
        setButtonColor(1);
      }}
    >
      <a>Barber</a>
    </button>
  </div>

  <div
    style={{
      flex: 1,
      display: "flex",
      height: 400,
      justifyContent: "center",
      alignItems: "center",
      //  width: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        height: 400,
        justifyContent: "center",
        alignItems: "center",
        width: "50%",
        borderWidth: 2,
        borderColor: "black",
        borderStyle: "solid", // You can also set other border styles like dashed, dotted, etc.
        borderRadius: 5,
      }}
    >
      <a>ssssssssss</a>
    </div>
  </div>
</div>
</div> */
}
