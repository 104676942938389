import React, { useState, useEffect } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { UserOutlined, PhoneFilled } from "@ant-design/icons";
import { AiOutlineMail } from "react-icons/ai";
import { Input, Button, Flex } from "antd";
import { useTranslation } from "react-i18next";
import axios from "axios";
const baseUrl = "https://mybarber.azurewebsites.net";

const { TextArea } = Input;

export default function Person() {
  const [ButtonClicked, setButtonClicked] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [ButtonColor, setButtonColor] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [YourName, setYourName] = useState(""); // Initialize state to hold input value
  const YourNameFunc = (e) => {
    setYourName(e.target.value); // Update state with new input value
  };

  const [YourPhoneNumber, setYourPhoneNumber] = useState(""); // Initialize state to hold input value
  const YourPhoneNumberFunc = (e) => {
    setYourPhoneNumber(e.target.value); // Update state with new input value
  };

  const [Message, setMessage] = useState(""); // Initialize state to hold input value
  const MessageFunc = (e) => {
    setMessage(e.target.value); // Update state with new input value
  };

  const [ShowError, setShowError] = useState(false); // Initialize state to hold input value

  const ClientSendRequest = async (event) => {
    var newObj = {
      name: YourName,
      phonenumber: YourPhoneNumber,
      message: Message,
      calledhem: "0",
    };
    try {
      //Array = Array.filter((item) => item !== timeTaken);

      const response = await axios.post(`${baseUrl}/ClientSendRequest`, newObj);
      console.log("Request Sent!");
      //setLoading(false);
    } catch (error) {
      console.log("error in Request Sent!");
    }
  };
  return (
    <div
      style={{
        height: 450,
        backgroundColor: "#fafafa",
        display: "flex",
        justifyContent: "center",
        //alignItems: "center",
        paddingTop: 20,
      }}
    >
      <div
        style={{
          //backgroundColor: "gray",
          width: window.innerWidth > 766 ? "40%" : "90%",
          height: "95%",
          borderRadius: 10,
          borderWidth: 0,
          //borderColor: "gray",
          borderStyle: "solid", // You can also set other border styles like dashed, dotted, etc.
          backgroundColor: "#ffffff" /* Set your background color */,
          boxShadow:
            "0px 0px 10px 0px rgba(0, 0, 0, 0.5)" /* Add a shadow at the bottom */,
        }}
      >
        <div
          style={{
            paddingTop: "5%",
            //backgroundColor: "yellow",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>

        <div
          style={{
            //backgroundColor: "yellow",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "70%",
              //backgroundColor: "green",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              direction: i18n.resolvedLanguage === "en" ? "" : "rtl",
            }}
          >
            <Input
              style={{ borderRadius: 5, height: 50 }}
              size="large"
              placeholder={t("yourname")}
              prefix={<UserOutlined />}
              value={YourName}
              onChange={YourNameFunc} // Set up onChange handler to update state with input value
            />
            <br />
          </div>
          <br />
          <br />
          <div
            style={{
              width: "70%",
              //backgroundColor: "green",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              direction: i18n.resolvedLanguage === "en" ? "" : "rtl",
            }}
          >
            <Input
              style={{ borderRadius: 5, height: 50 }}
              size="large"
              placeholder={t("yourphonenumber")}
              prefix={<FaPhoneAlt />}
              value={YourPhoneNumber}
              onChange={YourPhoneNumberFunc} // Set up onChange handler to update state with input value
            />
            <br />
          </div>
        </div>
        <br />
        <br />
        <div
          style={{
            //backgroundColor: "yellow",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "70%",
              //backgroundColor: "green",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              direction: i18n.resolvedLanguage === "en" ? "" : "rtl",
            }}
          >
            <TextArea
              rows={3}
              placeholder={t("message")}
              value={Message}
              onChange={MessageFunc} // Set up onChange handler to update state with input value
            />

            {/* <Input
          style={{ borderRadius: 5, height: 100 }}
          size="large"
          placeholder="Your message"
          //prefix={<AiOutlineMail />}
        /> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "3%",
          }}
        >
          {ButtonClicked ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  cursor: "",
                  width: "70%",
                  height: 60,
                  backgroundColor: "green", // Change the background color when hovered
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <a style={{ color: "white" }}>{t("sentReq")}</a>
              </div>
              {/* <Button
                type="primary"
                style={{
                  cursor: "",
                  width: "70%",
                  height: 60,
                  backgroundColor: "green", // Change the background color when hovered
                }}
              >
                The request sent successfully
                {t("submit")}
              </Button> */}
              <br />
              <a style={{ color: "green" }}>
                {t("wewillcontactyouAssoonaspossible")}
              </a>
            </div>
          ) : (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                type="primary"
                style={{
                  width: "70%",
                  height: 60,
                  backgroundColor: isHovered ? "#4f46e5" : "#818cf8", // Change the background color when hovered
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  if (YourName !== "" && YourPhoneNumber !== "") {
                    setButtonClicked(true);
                    setShowError(false);
                    console.log("Clicked!");
                    ClientSendRequest();
                  } else {
                    setShowError(true);
                    console.log("Please fill the name & phone number!");
                  }
                }}
              >
                {t("submit")}
              </Button>
              <br />
              {ShowError ? (
                <>
                  <a style={{ color: "#ef4444" }}>{t("error")}</a>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
