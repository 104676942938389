import React, { useState, useEffect } from "react";
import addapp7 from "../Images/addapp7.png";
import makeapp2 from "../Images/makeapp2.png";
import makeapp3 from "../Images/makeapp3.png";

import numbertwo from "../Images/numbertwo.png";
import numberone from "../Images/numberone.png";
import clientmakeapp1 from "../Images/clientmakeapp1.png";
import imgstatic1 from "../Images/imgstatic1.png";

export default function AdminComp2() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingTop: 30,
          flexDirection: "column",
        }}
      >
        <a
          style={{
            textAlign: "center",
            fontSize: 50,
            fontWeight: "bold",
            color: "#0077e6",
          }}
        >
          المزيد عنا
        </a>
        <div
          style={{
            borderWidth: 1,
            width: "75%",
            height: 6,
            backgroundColor: "#e7e5e4",
            borderRadius: 10,
          }}
        ></div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              paddingTop: 15,
              paddingBottom: 15,
              flex: 1,
            }}
          >
            <a style={{ fontWeight: "bold", fontSize: 35 }}>
              اضافة مواعيد للحجز
            </a>
            <a style={{ fontSize: 25, textAlign: "center", paddingTop: 5 }}>
              بضغطة زر تضيف قائمة مواعيد مع مدة كل موعد وفي اي تاريخ تريد
            </a>
          </div>

          <img
            src={addapp7}
            alt="logo"
            style={{
              width: window.innerWidth > 766 ? "50%" : "100%",
            }}
          />
        </div>

        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            paddingTop: 15,
          }}
        >
          <a style={{ fontWeight: "bold", fontSize: 35 }}>حجز مواعيد</a>
          <a style={{ fontSize: 25, paddingTop: 5 }}>
            هناك طريقتين لحجز المواعيد
          </a>
          <div style={{ paddingTop: 8 }}></div>
          <a
            style={{
              fontSize: 22,
              direction: "rtl",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {" "}
            <img
              src={numberone}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            يمكنك انت ان تحجز المواعيد للزبائن
          </a>
          <div style={{ paddingTop: 15 }}></div>
          <img
            src={makeapp2}
            alt="logo"
            style={{
              width: window.innerWidth > 766 ? "50%" : "100%",
            }}
          />{" "}
          <div style={{ paddingTop: 25 }}></div>
          <a
            style={{
              fontSize: 25,
              direction: "rtl",
              fontWeight: "bold",
              paddingTop: 5,
              textAlign: "center",
            }}
          >
            {" "}
            <img
              src={numbertwo}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "}
            و/او الزبون يحجز موعدة من تطبيق الزبائن MyBarber
          </a>
          <div style={{ paddingTop: 5 }}></div>
          <img
            src={clientmakeapp1}
            alt="logo"
            style={{
              width: window.innerWidth > 766 ? "50%" : "100%",
            }}
          />{" "}
          <div style={{ paddingTop: 25 }}></div>
          <a
            style={{
              fontSize: 25,
              direction: "rtl",
              fontWeight: "bold",
              paddingTop: 5,
            }}
          >
            {" "}
            {/* <img
              src={numberone}
              alt="logo"
              style={{
                width: 25,
              }}
            />{" "} */}
            يمكن ايضا تعديل/حذف/اتصال اوارسال رسالة{" "}
          </a>
          <div style={{ paddingTop: 5 }}></div>
          <img
            src={makeapp3}
            alt="logo"
            style={{
              width: window.innerWidth > 766 ? "50%" : "100%",
            }}
          />{" "}
          <div style={{ paddingTop: 25 }}></div>
          <a
            style={{
              fontSize: 35,
              direction: "rtl",
              fontWeight: "bold",
              paddingTop: 5,
            }}
          >
            الاحصائيات بمكان واحد{" "}
          </a>
          <a
            style={{
              fontSize: 25,
              direction: "rtl",
              paddingTop: 5,
              textAlign: "center",
            }}
          >
            بدك تعرف عدد الحجوزات لكل شهر, والدخل اللك, وقائمة الزبائن الي حجزو
            بكل تاريخ؟ كل هذا من مكان واحد
          </a>
          <div style={{ paddingTop: 5 }}></div>
          <img
            src={imgstatic1}
            alt="logo"
            style={{
              width: window.innerWidth > 766 ? "50%" : "100%",
            }}
          />{" "}
          <div style={{ paddingTop: 25 }}></div>
        </div>
      </div>
    </div>
  );
}
